import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const epohoordemass = () => (
  <Layout>
    <SEO title="Epohõõrdemass" />

    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
            <h1>EPOHÕÕRDEMASS</h1>
            <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
    </div>

    <div
      className="epoporandad"
      style={{
        maxWidth: 1300,
        margin: "auto",
      }}
    >
      <div className="wrapper-epo">
        <Link
          to="/epoporandad/antistaatilised/"
          activeStyle={{ border: "30px solid green" }}
        >
          <div className="epo antistaatilised">
            <h2>Antistaatilised</h2>
            <div className="epo-img epo-img1"></div>
          </div>
        </Link>
        <Link
          to="/epoporandad/epohoordemass/"
          activeStyle={{ borderBottom: "3px solid #ffa609" }}
        >
          <div className="epo epohoordemass">
            <h2>epohõõrdemass</h2>
            <div className="epo-img epo-img2"></div>
          </div>
        </Link>
        <Link to="/epoporandad/isetasanduv/">
          <div className="epo isetasanduv-epomasskate">
            <h2>isetasanduv epomasskate</h2>
            <div className="epo-img epo-img3"></div>
          </div>
        </Link>
        <Link to="/epoporandad/treppidele/">
          <div className="epo treppidele">
            <h2>treppidele</h2>
            <div className="epo-img epo-img4"></div>
          </div>
        </Link>
        <Link to="/epoporandad/dekoratiivporandad/">
          <div className="epo dekoratiivporandad">
            <h2>dekoratiivpõrandad</h2>
            <div className="epo-img epo-img5"></div>
          </div>
        </Link>
        <Link to="/epoporandad/lasketiiru/">
          <div className="epo lasketiiru">
            <h2>Lasketiiru põrandad</h2>
            <div className="epo-img epo-img6"></div>
          </div>
        </Link>
      </div>

      <div className="epohoordemass-wrapper">
        <div className="epohoordemass-text">
          <h3>Epohõõrdemass</h3>
          <p>
            Epoliivamass ehk hõõrdemass koosneb läbibaistvast epolakist ja
            liivast. Liiv on kas värvitud (värviline), looduslik filtreeritud
            või kvartsliiv. Hõõrdemassid sobivad eriti hästi suure
            temperatuurikoormusega põrandatele, nagu toiduainetetööstus, kus
            põrandaid tuleb puhastada kuuma vee ja auruga. Kuna hõõrdemassi
            liiva sisaldus on suur, soojuspaisumine aga väiksem on ta omadustelt
            lähedane betoonile. Hõõrdemassid taluvad hästi lööke ja muud tugevat
            mehhaanilist koormust. Neid on hea karestada ja sobivad seetõttu
            hästi kohtadesse, kus põrandatel on palju vett, õli ja rasva.
            Hõõrdemassidega saab väga hea tulemuse vanade põrandate
            renoveerimisel. Kasutusiga väga pikk.
          </p>
          <p>
            Kasutusala: tugeva mehaanilise ja keemilise koormusega tööstus-,
            remondi- ja laoruumid, toiduainete- ja keemiatööstused. Sobib hästi
            ka suurköökide põrandaks.
          </p>
        </div>
        <div className="subpage-img epohoordemass-img"></div>
      </div>
    </div>
  </Layout>
)

export default epohoordemass
